import React, { useCallback, useMemo } from "react";
import { Container, Navbar, Offcanvas, Nav } from "react-bootstrap";
import "../Header/header.css";

const Header = React.memo(({ aboutRef, toursRef, servicesRef, contactRef }) => {
  
  // Memoize scroll handler to avoid re-creation
  const scrollToSection = useCallback((ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // Memoize nav links to avoid unnecessary re-renders
  const navLinks = useMemo(() => [
    { label: "About Us", ref: aboutRef },
    { label: "Tours", ref: toursRef },
    { label: "Services", ref: servicesRef },
    { label: "Contact", ref: contactRef },
  ], [aboutRef, toursRef, servicesRef, contactRef]);

  return (
    <header className="header-section">
      <Container>
        <Navbar expand="lg" className="p-0">
          {/* Logo Section */}
          <Navbar.Brand className="logo">
            <a href="/">SL Holidays</a>
          </Navbar.Brand>

          {/* Mobile Menu Toggle Button */}
          <div className="ms-auto d-lg-none">
            <button className="toggle-btn">
              <i className="bi bi-list"></i>
            </button>
          </div>

          {/* Offcanvas Navigation */}
          <Navbar.Offcanvas id="offcanvasNavbar-expand-lg" placement="start">
            <Offcanvas.Header closeButton>
              <h1 className="logo">SL Holidays</h1>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="ms-auto">
                {navLinks.map((link) => (
                  <Nav.Link
                    key={link.label}
                    onClick={() => scrollToSection(link.ref)}
                  >
                    {link.label}
                  </Nav.Link>
                ))}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </Container>
    </header>
  );
});

export default Header;
