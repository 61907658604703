import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Footer/footer.css"; // Import the updated CSS

const Footer = () => {
  const [visible, setVisible] = useState(false);

  // Attach scroll listener and cleanup
  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > 300);
    };

    window.addEventListener("scroll", toggleVisible);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="pt-5 footer-section">
        <Container>
          <Row>
            {/* Company Info */}
            <Col md="6" sm="12" className="company_info mt-3">
              <h4>SL Holidays</h4>
              <p>
                SL Holidays is your trusted partner in creating unforgettable travel experiences across Sri Lanka. We offer personalized tours, incredible itineraries, and the best customer service.
              </p>
              <p>Explore with us and discover the wonders of Sri Lanka.</p>
            </Col>

            {/* Contact Info */}
            <Col md="6" sm="12" className="location mt-3">
              <h4>Contact Info</h4>
              <p>
                385 Galle Road, Landmark Building, Colombo 03, Kollupitiya, Sri
                Lanka
              </p>
              <div className="d-flex align-items-top my-2">
                <i className="bi bi-envelope me-3"></i>
                <a href="mailto:info@slholidays.lk" className="d-block">
                  info@slholidays.lk
                </a>
              </div>
              <div className="d-flex align-items-top">
                <i className="bi bi-telephone me-3"></i>
                <a href="tel:+94777065418" className="d-block">
                  +94 777 065 418
                </a>
              </div>
            </Col>
          </Row>

          {/* Copyright Row */}
          <Row className="py-2 mt-4 border-top">
            <Col className="text-center copyright">
              <p>© 2024 SL Holidays. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* Back to top button */}
      <div
        id="back-top"
        onClick={scrollTop}
        className={visible ? "active" : ""}
        style={{ display: visible ? "block" : "none" }} // Ensure visibility
      >
        <i className="bi bi-arrow-up"></i>
      </div>
    </>
  );
};

export default Footer;
