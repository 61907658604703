import React, { useState, useRef, Suspense } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import aboutImg from "../../assets/images/gallery/ayubowan.jpg";
import { destinationsData, servicesData } from "../../utils/data";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA

// Lazy load components
const Banner = React.lazy(() => import("../../components/Banner/Banner"));
const Features = React.lazy(() => import("../../components/Features/Features"));
const Gallery = React.lazy(() => import("../../components/Gallery/Gallery"));
const Cards = React.lazy(() => import("../../components/Cards/Cards"));
const ServiceCard = React.lazy(() =>
  import("../../components/Cards/ServiceCard")
);

const Home = ({ aboutRef, toursRef, servicesRef, contactRef }) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
    setCaptchaVerified(true); // Update the state when the captcha is successfully completed
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }

    // Proceed with form submission (email sending logic goes here)
    alert("Form submitted successfully!");
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  return (
    <>
      {/* Use Suspense for lazy-loaded components */}
      <Suspense fallback={<div>Loading Banner...</div>}>
        <Banner />
      </Suspense>

      <Suspense fallback={<div>Loading Features...</div>}>
        <Features />
      </Suspense>

      <section className="tours_section slick_slider" ref={toursRef}>
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1> Featured Tours </h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Suspense fallback={<div>Loading Tours...</div>}>
                <Slider {...settings}>
                  {destinationsData.map((destination, inx) => {
                    return <Cards destination={destination} key={inx} />;
                  })}
                </Slider>
              </Suspense>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="popular py-5" ref={servicesRef}>
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1> Our Services </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Suspense fallback={<div>Loading Services...</div>}>
              {servicesData.map((service, inx) => (
                <Col md={3} sm={6} xs={12} className="mb-5" key={inx}>
                  <ServiceCard service={service} />
                </Col>
              ))}
            </Suspense>
          </Row>
        </Container>
      </section>

      <section className="call_us">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h5 className="title">Plan Your Journey Today</h5>
              <h2 className="heading">Your Adventure Awaits!</h2>
              <p className="text">
                Whether you’re looking for relaxation or adventure, SL Holidays
                has the perfect trip for you.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="overlay"></div>
      </section>

      <section className="about_us py-5" ref={aboutRef}>
        <Container>
          <Row className="align-items-center">
            <Col md="6">
              <h2>About SL Holidays</h2>
              <p>
                SL Holidays is a premier travel agency based in Sri Lanka. We
                offer custom travel packages tailored to your preferences. With
                over a decade of experience, we pride ourselves on providing
                exceptional service, unique itineraries, and unforgettable
                experiences.
              </p>
              <p>
                Our team is passionate about travel and dedicated to ensuring
                that your holiday is stress-free, enjoyable, and filled with
                adventure.
              </p>
            </Col>
            <Col md="6">
              <img src={aboutImg} alt="About Us Image" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gallery">
        <Container>
          <Row>
            <Col md="12">
              <Suspense fallback={<div>Loading Gallery...</div>}>
                <Gallery />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Contact Form Section */}
      <section className="contact_form py-5" ref={contactRef}>
        <Container>
          <Row>
            <Col md="12" className="text-center mb-4">
              <h2>Contact Us</h2>
              <p>
                We'd love to hear from you! Fill out the form below, and one of
                our representatives will get in touch with you soon.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="8" className="mx-auto">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Your Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Your Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="formSubject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" placeholder="Enter the subject" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMessage">
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control as="textarea" rows={5} placeholder="Enter your message" />
                </Form.Group>
                <Form.Group className="mb-4">
                  {/* <ReCAPTCHA
                    sitekey="YOUR_SITE_KEY" // Replace with your site key
                    onChange={onChangeCaptcha}
                  /> */}
                </Form.Group>
                <div className="text-center">
                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
