import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import { useRef } from "react";

function App() {
  // Declare the section references in App.js
  const aboutRef = useRef(null);
  const toursRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      {/* Pass the refs to the Header component */}
      <Header
        aboutRef={aboutRef}
        toursRef={toursRef}
        servicesRef={servicesRef}
        contactRef={contactRef}
      />

      {/* Pass the same refs to the Home component */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              aboutRef={aboutRef}
              toursRef={toursRef}
              servicesRef={servicesRef}
              contactRef={contactRef}
            />
          }
        />
      </Routes>

      <Footer
        aboutRef={aboutRef}
        toursRef={toursRef}
        servicesRef={servicesRef}
        contactRef={contactRef}
      />
    </>
  );
}

export default App;
