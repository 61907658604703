// destinations img
import tour4 from "../assets/images/tour/tourImg1.jpg";
import tour5 from "../assets/images/tour/tourImg2.jpg";
import tour6 from "../assets/images/tour/tourImg3.jpg";
import tour7 from "../assets/images/tour/tourImg4.jpg";
import tour8 from "../assets/images/tour/tourImg5.jpg";
import tour9 from "../assets/images/tour/tourImg7.jpg";
import tour10 from "../assets/images/tour/tourImg8.jpg";
import tour11 from "../assets/images/tour/tourImg9.webp";


// populars img
import Anchorage from "../assets/images/popular/Anchorage To La Paz.jpg";
import Singapore from "../assets/images/popular/Discover Singapore.png";
import Kiwiana from "../assets/images/popular/Kiwiana Panorama.jpg";
import Quito from "../assets/images/popular/Anchorage To Quito.jpg";
import Cuzco from "../assets/images/popular/Cuzco To Anchorage.jpg";
import Ushuaia from "../assets/images/popular/Anchorage To Ushuaia.jpg";
import Santiago from "../assets/images/popular/Anchorage To Santiago.jpg";
import Explorer from "../assets/images/popular/LA Explorer.jpg";

// tour detail img
import image1 from "../assets/images/new/1.jpg";
import image2 from "../assets/images/new/2.jpg";
import image3 from "../assets/images/new/3.jpg";
import image4 from "../assets/images/new/4.jpg";
import image5 from "../assets/images/new/5.jpg";
import image6 from "../assets/images/new/6.jpg";
import image7 from "../assets/images/new/7.jpg";
import image8 from "../assets/images/new/8.jpg";

import service1Image from '../assets/images/popular/serviceImg1.webp'
import service2Image from '../assets/images/popular/serviceImg2.webp'
import service3Image from '../assets/images/popular/serviceImg3.webp'
import service4Image from '../assets/images/popular/serviceImg4.webp'

export const destinationsData = [
  {
    id: 0,
    name: "Sigiriya Rock Fortress",
    tours: "3 tours and activities",
    image: tour4, 
    link: "sigiriya-tour",
    shortDes: "Explore the ancient rock fortress, a UNESCO World Heritage Site.",
  },
  {
    id: 1,
    name: "Temple of the Tooth Relic - Kandy",
    tours: "4 tours and activities",
    image: tour5, // Replace with actual image variable
    link: "temple-of-tooth-kandy-tour",
    shortDes: "Visit the sacred Temple of the Tooth Relic in the heart of Kandy.",
  },
  {
    id: 2,
    name: "Ella Adventure Tours",
    tours: "5 tours and activities",
    image: tour6, // Replace with actual image variable
    link: "ella-adventure-tour",
    shortDes: "Enjoy hiking, waterfalls, and scenic train rides in Ella.",
  },
  {
    id: 3,
    name: "Yala National Park Safari",
    tours: "4 tours and activities",
    image: tour7, // Replace with actual image variable
    link: "yala-safari-tour",
    shortDes: "Experience a wildlife safari and spot leopards, elephants, and more.",
  },
  {
    id: 4,
    name: "Galle Fort and City Tour",
    tours: "3 tours and activities",
    image: tour8, // Replace with actual image variable
    link: "galle-city-tour",
    shortDes: "Walk through the historical Galle Fort and explore colonial landmarks.",
  },
  {
    id: 5,
    name: "Mirissa Whale Watching",
    tours: "2 tours and activities",
    image: tour9, // Replace with actual image variable
    link: "whale-watching-mirissa",
    shortDes: "Go whale watching in Mirissa, one of the best places for marine life.",
  },
  {
    id: 6,
    name: "Tea Plantations and Factory Tour - Nuwara Eliya",
    tours: "3 tours and activities",
    image: tour10, // Replace with actual image variable
    link: "tea-plantation-nuwara-eliya",
    shortDes: "Visit scenic tea plantations and learn about tea processing in the highlands.",
  },
  {
    id: 7,
    name: "Bentota Beach and Water Sports",
    tours: "4 tours and activities",
    image: tour11, // Replace with actual image variable
    link: "bentota-water-sports",
    shortDes: "Relax at Bentota beach and try out water sports like jet skiing and snorkeling.",
  },
];


export const popularsData = [
  {
    id: 0,
    title: "Discover Singapore",
    image: Singapore,
    location: "European Way, Southampton, United Kingdom",
    category: ["Escorted Tour", "Rail Tour"],
    days: "5 days - 4 nights",
    price: 100,
    afterDiscount: 92,
    rating: 3,
    reviews: 5,
  },
  {
    id: 1,
    title: "Kiwiana Panorama",
    image: Kiwiana,
    location: "European Way, Southampton, United Kingdom",
    category: ["River Cruise", "Wildlife"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 4,
    reviews: 9,
  },
  {
    id: 2,
    title: "Anchorage To Quito",
    image: Quito,
    location: "European Way, Southampton, United Kingdom",
    category: ["Escorted Tour", "River Cruise"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 4,
    reviews: 9,
  },
  {
    id: 3,
    title: "Anchorage To La Paz",
    image: Anchorage,
    location: "European Way, Southampton, United Kingdom",
    category: ["River Cruise", "Rail Tour"],
    days: "unlimited",
    price: 434,
    afterDiscount: 0,
    rating: 5,
    reviews: 20,
  },
  {
    id: 4,
    title: "Cuzco To Anchorage",
    image: Cuzco,
    location: "European Way, Southampton, United Kingdom",
    category: ["River Cruise", "Tour & Cruise"],
    days: "1 days - 9 hours",
    price: 395,
    afterDiscount: 0,
    rating: 3,
    reviews: 12,
  },
  {
    id: 5,
    title: "Anchorage To Ushuaia",
    image: Ushuaia,
    location: "European Way, Southampton, United Kingdom",
    category: ["Escorted Tour", "Wildlife"],
    days: "5 days - 4 nights",
    price: 93,
    afterDiscount: 0,
    rating: 3,
    reviews: 12,
  },
  {
    id: 6,
    title: "Anchorage To Santiago",
    image: Santiago,
    location: "European Way, Southampton, United Kingdom",
    category: ["Escorted Tour", "Wildlife"],
    days: "1 day - 1 night",
    price: 42,
    afterDiscount: 0,
    rating: 5,
    reviews: 18,
  },
  {
    id: 7,
    title: "LA Explorer",
    image: Explorer,
    location: "European Way, Southampton, United Kingdom",
    category: ["Rail Tour", "Tour & Cruise"],
    days: "1 night",
    price: 99,
    afterDiscount: 0,
    rating: 4,
    reviews: 22,
  },
];

export const servicesData = [
  {
    id: 0,
    title: "Professionalism & Reliability",
    image: service1Image, // Replace with actual image variable for service icon
    description: "We pride ourselves on professionalism and reliability in every aspect of our service.",
  },
  {
    id: 1,
    title: "24/7 Travel Consultant Team",
    image: service2Image, // Replace with actual image variable for service icon
    description: "Our team is available 24/7 to assist you with your travel needs.",
  },
  {
    id: 2,
    title: "Excellent Value for Money",
    image: service3Image, // Replace with actual image variable for service icon
    description: "We provide excellent value for money, offering competitive prices without compromising quality.",
  },
  {
    id: 3,
    title: "Exceptional Flexibility",
    image: service4Image, // Replace with actual image variable for service icon
    description: "We offer flexible booking arrangements with speedy and responsive services.",
  },
  // {
  //   id: 4,
  //   title: "Strong Local Connections",
  //   image: service5Image, // Replace with actual image variable for service icon
  //   description: "We possess in-depth knowledge and strong local connections for a seamless travel experience.",
  // },
  // {
  //   id: 5,
  //   title: "Well Maintained Fleet",
  //   image: service6Image, // Replace with actual image variable for service icon
  //   description: "Our fleet of vehicles is well-maintained and available for all types of travel needs.",
  // },
];


export const tourDetails = {
  title: "Beautiful Bali with Malaysia",
  des: ` Bali, also known as the land of gods has plenty to offer to travelers from across the globe. As it so contrasted oh estimating instrument. Size like body some one had.  Are conduct viewing boy minutes warrant the expense?  Tolerably behavior may admit daughters offending her ask own. Praise effect wishes change way and any wanted.  Lively use looked latter regard had. Do he it part more  last in. We understand that theory is important to build a solid foundation, we understand that theory alone isn’t going to get the job done so that’s why this is packed with practical hands-on examples that you can  follow step by step.`,
  price: "280.00",
  rating: " 4.5",
  reviews: "365 reviews",
  tourInfo: [
    '<strong className="font-bold"> Place Covered</strong>: Bali - Ubud',
    ' <strong className="font-bold">Duration:</strong>5 Days, 4 Nights',
    '<strong className="font-bold">Start Point:</strong> Ngurah International Airport',
    '<strong className="font-bold">End Point:</strong>  Ngurah International Airport',
  ],

  highlights: [
    " Experience a delightful tropical getaway with a luxurious stay and witness the picture-perfect beaches, charming waterfalls and so much more",
    " Dependent on so extremely delivered by. Yet no jokes  worse her why. Bed one supposing breakfast day fulfilled off depending questions.",
    " Whatever boy her exertion his extended. Ecstatic  followed handsome drawings entirely Mrs one yet  outweigh.",
    "Meant balls it if up doubt small purse. Required his  you put the outlived answered position. A pleasure exertion if believed provided to.",
  ],

  itinerary: [
    {
      title: `<span class="me-1 fw-bold">Day 1:</span>  Airport Pick Up `,
      des: ` Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.`,
    },

    {
      title: `<span class="me-1 fw-bold">Day 2:</span>  Temples & River Cruise `,
      des: ` Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in. `,
    },
    {
      title: `<span class="me-1 fw-bold">Day 3:</span>  Massage & Overnight Train`,
      des: ` Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.`,
    },
    {
      title: `<span class="me-1 fw-bold">Day 4:</span>  Khao Sok National Park `,
      des: ` Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.`,
    },
    {
      title: `<span class="me-1 fw-bold">Day 5:</span>  Travel to Koh Phangan `,
      des: ` Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.
      `,
    },
    {
      title: `<span class="me-1 fw-bold">Day 6:</span> Morning Chill & Muay Thai Lesson `,
      des: `Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.
      `,
    },
  ],

  included: [
    "Comfortable stay for 4 nights in your preferred category Hotels",
    "Professional English speaking guide to help you explore the cities",
    "Breakfast is included as mentioned in Itinerary.",
    "Per Peron rate on twin sharing basis",
    "Entrance Tickets to Genting Indoor Theme Park    ",
    "All Tours & Transfers on Seat In Coach Basis ",
    "Visit Bali Safari & Marine Park with Jungle Hopper Pass    ",
  ],
  exclusion: [
    "Lunch and dinner are not included in CP plans",
    "Any other services not specifically mentioned in the inclusions",
    "Medical and Travel insurance",
    "Airfare is not included ",
    "Early Check-In & Late Check-Out ",
    "Anything which is not specified in Inclusions    ",
  ],

  images: [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image4,
      thumbnail: image4,
    },
    {
      original: image5,
      thumbnail: image5,
    },

    {
      original: image6,
      thumbnail: image6,
    },
    {
      original: image7,
      thumbnail: image7,
    },
    {
      original: image8,
      thumbnail: image8,
    },
  ],
};

export const location = [
  "Bali",
  "Tokyo",
  "Bangkok",
  "Cancun",
  "Nha Trang",
  "Phuket",
  "Malaysia",
  "Paris",
];

export const Categories = [
  "History",
  "Calture",
  "Netural",
  "Urban Tour",
  "Relax",
];

export const Duration = ["1-3 Days", "3-5 Days", "5-7 Days", "7-10 Day"];
export const PriceRange = [
  "$ 0 - $50",
  "$ 50 - $ 100",
  "$ 100 - $ 200",
  "$ 200 - ₹ $ 400",
  "$ 400 - ₹ $ 800",
];

export const Ratings = ["⭐", "⭐⭐", "⭐⭐⭐", "⭐⭐⭐⭐", "⭐⭐⭐⭐⭐"];
